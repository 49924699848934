<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#F1F4FB;"
      d="M493.037,474.074H18.963C8.489,474.074,0,465.585,0,455.111V56.889
	c0-10.473,8.489-18.963,18.963-18.963h474.074c10.473,0,18.963,8.489,18.963,18.963v398.222
	C512,465.585,503.511,474.074,493.037,474.074z"
    />
    <circle style="fill:#FFFFFF;" cx="256" cy="293.926" r="161.185" />
    <path
      style="fill:#D5DCED;"
      d="M113.778,37.926h379.259c10.473,0,18.963,8.489,18.963,18.963v18.963H113.778V37.926z"
    />
    <path
      style="fill:#959CB5;"
      d="M206.38,379.259l19.825-33.047l-16.26-9.759L181.5,383.861c-1.759,2.931-1.805,6.579-0.121,9.551
	c1.684,2.972,4.833,4.81,8.25,4.81h151.704v-18.963H206.38z"
    />
    <path
      style="fill:#FF507D;"
      d="M18.963,37.926h18.963v37.926H0V56.889C0,46.415,8.489,37.926,18.963,37.926z"
    />
    <rect
      x="37.926"
      y="37.926"
      style="fill:#FFDC64;"
      width="37.926"
      height="37.926"
    />
    <rect
      x="75.852"
      y="37.926"
      style="fill:#B4E66E;"
      width="37.926"
      height="37.926"
    />
    <g>
      <path
        style="fill:#C7CFE2;"
        d="M474.074,37.926h18.963c10.473,0,18.963,8.489,18.963,18.963v18.963h-37.926V37.926z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M483.556,113.778H28.444c-5.236,0-9.481-4.245-9.481-9.481l0,0c0-5.236,4.245-9.481,9.481-9.481
		h455.111c5.236,0,9.481,4.245,9.481,9.481l0,0C493.037,109.532,488.792,113.778,483.556,113.778z"
      />
    </g>
    <path
      style="fill:#AFB9D2;"
      d="M221.037,349.112c-11.74,0-22.361-7.218-26.584-18.463l-47.019-125.384
	c-1.38-3.685-4.945-6.152-8.87-6.152h-15.305c-5.241,0-9.481-4.245-9.481-9.481c0-5.236,4.241-9.481,9.481-9.481h15.305
	c11.796,0,22.5,7.422,26.63,18.468l7.296,19.457h187.806c5.241,0,9.481,4.245,9.481,9.481v78.074
	c0,14.315-10.713,26.449-24.916,28.223l-120.241,15.033C223.417,349.037,222.222,349.112,221.037,349.112z M179.602,237.037
	l32.612,86.954c1.547,4.124,5.741,6.601,10.055,6.079l120.241-15.033c4.731-0.593,8.306-4.634,8.306-9.408v-68.591H179.602z"
    />
    <g>
      <circle style="fill:#464655;" cx="189.63" cy="388.741" r="18.963" />
      <circle style="fill:#464655;" cx="341.333" cy="388.741" r="18.963" />
    </g>
    <path
      style="fill:#FF507D;"
      d="M300.629,258.778c-3.704-3.704-9.704-3.704-13.408,0l-12.258,12.26V170.667
	c0-5.236-4.241-9.481-9.481-9.481c-5.241,0-9.481,4.245-9.481,9.481v100.371l-12.26-12.26c-3.704-3.704-9.704-3.704-13.408,0
	c-3.704,3.704-3.704,9.704,0,13.408l28.444,28.444c1.852,1.852,4.277,2.778,6.703,2.778s4.852-0.926,6.703-2.778l28.444-28.444
	C304.333,268.481,304.333,262.482,300.629,258.778z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
